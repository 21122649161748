



























import Vue from 'vue'
import Dropdown from 'primevue/dropdown'
import { get, set } from 'lodash'
import { mapGettersTyped, mapStateTyped } from '@/store'

type Option = {
  title: string
  value: string
}

interface FieldOptions {
  title: string
  type: 'enum'
  editable: boolean
  showLabel: boolean
  options: Option[]
}

type Context = {
  excludeOptions?: {
    [key in FieldOptions['title']]: Option['value'][]
  }
}

export default Vue.extend({
  name: 'EnumField',
  components: {
    Dropdown,
  },
  props: {
    entity: Object,
    options: Object as () => FieldOptions,
    context: Object as () => Context,
  },
  computed: {
    ...mapGettersTyped(['te']),
    ...mapStateTyped(['entityType']),
    calcOptions(): Option[] {
      if (
        Array.isArray(get(this.context?.excludeOptions, this.options.title))
      ) {
        return this.options.options.filter(
          ({ value }) =>
            !get(this.context?.excludeOptions, this.options.title)?.includes(
              value,
            ),
        )
      } else {
        return this.options.options
      }
    },
    localValue: {
      set(val: string) {
        const entity = { ...this.entity }
        set(entity, this.options.title, val)
        this.$emit('commit', entity)
      },
      get(): Object {
        return get(this.entity, this.options.title) || ''
      },
    },
  },
  methods: {
    getLocale(val: any): string {
      const option = this.options.options.find(
        ({ value }: any) => value === val,
      )
      return option
        ? String(this.te(`${option.title}`))
        : ''
    }
  },

})
