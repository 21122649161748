

















import { mapStateTyped } from '@/store'
import Vue from 'vue'
export default Vue.extend({
  name: 'ScheduleField',
  props: {
    entityType: String,
    entity: Object,
    options: Object,
    context: Object,
  },
  data() {
    return {
      showScheduleDialog: false,
      unmount: () => {},
    }
  },
  computed: {
    ...mapStateTyped(['spaParent', 'entityType']),
  },
  watch: {
    showScheduleDialog(val) {
      if (val) {
        this.mountDialog()
      } else {
        this.unmount()
      }
    },
  },
  destroyed() {
    this.unmount()
  },
  methods: {
    mountDialog() {
      const ref = this.$refs.ScheduleDialog
      // @ts-ignore
      const scheduleDialog = this.spaParent.areas.find(
        (e: any) => e.name === 'schedule',
      )
      if (scheduleDialog !== undefined) {
        // @ts-ignore
        this.unmount = this.spaParent.mountChildren(
          ref,
          scheduleDialog.children.map((e: any) => {
            return {
              ...e,
              // @ts-ignore
              props: {
                ...e.props,
                tboContainerAreaId: this.entity.id,
                state: {
                  get: async () => {
                    return this.entity[this.options.title] || null
                  },
                  set: async (value: string) => {
                    const entity = { ...this.entity }
                    entity[this.options.title] = value
                    this.$emit('commit', entity)
                    return true
                  },
                },
                onHide: () => {
                  this.showScheduleDialog = false
                },
              },
            }
          }),
        )
      }
    },
  },
})
