













import { mapStateTyped } from '@/store'
import Vue from 'vue'
import EnumField from './EnumField.vue'

export default Vue.extend({
  name: 'TypeField',
  components: {
    EnumField,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      typesList: [] as any,
    }
  },
  computed: {
    ...mapStateTyped(['api']),
  },
  mounted() {
    this.fetchEntityTypes()
  },
  methods: {
    async fetchEntityTypes() {
      const { results } = await this.api.getEntitiesList({
        limiter: { type: 'EntityTypeMetadata', limit: 1000 },
        filter: {
          q: [
            {
              key: 'entityType',
            },
          ],
        },
      })
      this.typesList = results.map(({ entityType }: any) => ({
        value: entityType,
        title: entityType,
      }))
    },
  },
})
