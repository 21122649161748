import { mapState, mapMutations, mapGetters } from 'vuex'
import { IRepository } from '@netvision/lib-api-repo'
import { RUSSIAN_TRANSLATIONS } from './translations/ru'
import { get, merge } from 'lodash'

interface Area {
  name: string
  children: object[]
}

const initialState = () =>
  ({
    isDarkTheme: true as boolean,
    entityType: '' as string,
    isNewEntity: false as boolean,
    spaProps: {} as Object,
    spaParent: {} as {
      areas: Area[]
      mountChildren(
        ref: Vue | Element | Vue[] | Element[],
        childrens: any,
      ): () => void
    },
    api: {} as IRepository,
    defaultMapLocation: '53.20017221, 50.12033860' as string,
    widgetUUID: '' as string,
    strictRegEx: /[&?/#<>"'=;()]/,
    required: {} as Record<string, string[]>,
    existingReferenceIds: ['', undefined] as string[],
    maxChars: 25,
    currentLocale: 'ru' as string,
    locale: {
      ru: RUSSIAN_TRANSLATIONS,
    } as WidgetProps['locale'],
  } as const)

type State = ReturnType<typeof initialState>

export const mapStateTyped = <
  T extends keyof State,
  G extends { [Key in T]: () => State[Key] }
>(
  keys: T[],
): G => {
  return { ...mapState(keys) } as G
}

const getters = {
  te: ({ locale, currentLocale }: State) => (path: string, orThis?: string) => {
    return get(locale?.[currentLocale], path) || orThis || path
  },
}

type Getters = typeof getters
export const mapGettersTyped = <
  T extends keyof Getters,
  G extends { [Key in T]: () => ReturnType<Getters[Key]> }
>(
  keys: T[],
): G => {
  return mapGetters(keys) as G
}

const mutations = {
  setValue<T extends keyof State>(state: State, [key, value]: [T, State[T]]) {
    state[key] = value
  },
  addLocale(
    state: State,
    [entityType, localization]: [string, WidgetProps['locale']],
  ) {
    const typeLocale = Object.entries(localization).reduce(
      (acc, [key, val]) => {
        acc[key] = { [entityType]: val }
        return acc
      },
      {} as Partial<WidgetProps['locale']>,
    )
    // @ts-ignore
    state.locale = merge(state.locale, typeLocale)
  },
  reset(state: State) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      // @ts-ignore
      state[key] = s[key]
    })
  },
}

type Mutations = typeof mutations

export const mapMutationsTyped = <
  T extends keyof Mutations,
  G extends {
    [K in T]: (I?: Parameters<Mutations[K]>[1]) => ReturnType<Mutations[K]>
  }
>(
  keys: T[],
): G => {
  return mapMutations(keys) as G
}
export default () => ({
  state: initialState(),
  mutations,
  getters,
})
