






























import { mapState, mapMutations } from 'vuex'
import Vue from 'vue'
import OverlayPanel from 'primevue/overlaypanel'

export default Vue.extend({
  name: 'LayersMenu',
  components: { OverlayPanel },
  props: {
    currentLayerName: String,
  },
  computed: {
    ...mapState(['spaProps']),
    layersButtons(): string[] {
      return this.spaProps.layers
        ? ['default', ...this.spaProps.layers.map(({ title }: any) => title)]
        : []
    },
  },
  methods: {
    ...mapMutations(['setValue']),
    menuToggle(ref: string, event: EventInit) {
      // @ts-ignore
      this.$refs[ref].toggle(event)
    },
    setLayerName(name: string) {
      localStorage.setItem('savedLayerName', name)
      this.$emit('update:currentLayerName', name)
    },
  },
})
