







import Vue from 'vue'
import { get, set } from 'lodash'
import { mapStateTyped } from '@/store'

export default Vue.extend({
  name: 'GroupsField',
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      groups: [],
    }
  },
  computed: {
    ...mapStateTyped(['api']),
    localValue: {
      set(val: string[]) {
        const entity = { ...this.entity }
        set(entity, this.options.title, val)
        this.$emit('commit', entity)
      },
      get(): string[] {
        return get(this.entity, this.options.title) || []
      },
    },
  },
  mounted() {
    this.fetchGroupsNames()
  },
  methods: {
    async fetchGroupsNames() {
      if (this.entity[this.options.title]) {
        const groups = await Promise.allSettled(
          this.entity[this.options.title].map((id: string) => {
            return this.api
              .getEntity({
                id,
                type: 'Groups',
              })
              .then(({ entity }: any) => entity)
          }),
        )
        // @ts-ignore
        this.groups = groups.map(({ value }) => value.title)
      }
    },
  },
})
