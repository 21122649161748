





































import { mapGettersTyped } from '@/store'
import Vue from 'vue'
export default Vue.extend({
  name: 'DeleteDialog',
  props: {
    show: Boolean,
    message: String,
    header: String,
  },
  data() {
    return {
      displayDeleteDialog: this.show,
    }
  },
  computed: {
    ...mapGettersTyped(['te']),
  },
  watch: {
    show() {
      this.toggleOverlayPanel()
    },
    displayDeleteDialog() {
      this.$emit('update:show', this.displayDeleteDialog)
    },
  },
  mounted() {
    this.show && this.toggleOverlayPanel()
  },
  methods: {
    toggleOverlayPanel(): void {
      // @ts-ignore
      this.$refs?.OverlayPanel.toggle()
      this.displayDeleteDialog = !this.displayDeleteDialog
    },
  },
})
