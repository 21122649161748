































import Vue from 'vue'
import AutocompleteField from '../common/AutocompleteField.vue'
import { get, set } from 'lodash'
import { debounce } from '@/utils'
import { mapGettersTyped, mapStateTyped } from '@/store'

export default Vue.extend({
  name: 'ReferenceField',
  components: {
    AutocompleteField,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      searchField: '',
      nameFetched: false,
      name: '',
    }
  },
  computed: {
    ...mapGettersTyped(['te']),
    ...mapStateTyped(['existingReferenceIds', 'api']),
    localValue: {
      set(val: string) {
        const entity = { ...this.entity }
        set(entity, this.options.title, val)
        this.$emit('commit', entity)
      },
      get(): string {
        return String(get(this.entity, this.options.title) || '')
      },
    },
  },
  watch: {
    localValue(val, prevVal) {
      if (val !== prevVal) {
        debounce(
          () => {
            this.fetchEntityTitle(val, this.options.entityType)
          },
          200,
          'ref',
        )
      }
    },
  },
  async mounted() {
    if (this.localValue) {
      this.name = await this.fetchEntityTitle(
        this.localValue,
        this.options.entityType,
      )
      this.nameFetched = true
    }
  },
  methods: {
    onSelect({ value }: { value: Record<string, string> }) {
      this.nameFetched = true
      this.name = value[this.options.labelField]
      this.localValue = value.id
    },
    async fetchEntityTitle(id: string, type: string): Promise<string> {
      try {
        const { entity } = await this.api.getEntity({
          id,
          type,
        })
        this.$store.commit('setValue', [
          'existingReferenceIds',
          Array.from(new Set([...this.existingReferenceIds, entity.id])),
        ])
        return entity[this.options.labelField] as string
      } catch (error) {
        console.error(error)
        return ''
      }
    },
  },
})
