













































import Vue from 'vue'
import InputMask from 'primevue/inputmask'
import { get, set } from 'lodash'
import { mapGettersTyped, mapStateTyped } from '@/store'

export default Vue.extend({
  name: 'AddressField',
  components: {
    InputMask,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGettersTyped(['te']),
    ...mapStateTyped(['strictRegEx']),
    localValue: {
      set(val: Record<string, string>) {
        const entity = { ...this.entity }
        entity[this.options.title] = val
        this.$emit('commit', entity)
      },
      get(): Record<string, string> {
        return this.entity[this.options.title] || {}
      },
    },
  },
  mounted() {
    if (get(this.entity, this.options.title) === undefined) {
      set(this.entity, this.options.title, this.localValue)
    }
  },
  methods: {
    setOrRemoveAddressField(val: string, key: string) {
      if (!val.length) {
        const l = { ...this.localValue }
        delete l[key]
        this.localValue = l
      }
    },
  },
})
