










































































import Vue from 'vue'
import AutocompleteField from '../common/AutocompleteField.vue'
import SelectButton from 'primevue/selectbutton'
import { mapGettersTyped, mapStateTyped } from '@/store'

interface User {
  id: string
  title: string
  fullName: string
}
interface Group {
  id: string
  title: string
}

export default Vue.extend({
  name: 'GroupOrUserField',
  components: {
    AutocompleteField,
    SelectButton,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      searchField: '',
      namesFetched: false,
      selectedField:
        this.entity.userId || this.entity.groupId
          ? this.entity.userId
            ? 'userId'
            : 'groupId'
          : 'all',
      names: new Map() as Map<string, string>,
    }
  },
  computed: {
    ...mapGettersTyped(['te']),
    ...mapStateTyped(['api']),
    fieldsKeys(): any {
      return [
        { value: 'groupId', label: this.te('permissions.group') },
        { value: 'userId', label: this.te('permissions.user') },
        { value: 'all', label: this.te('permissions.all') },
      ]
    },
    userValue: {
      set(val: string) {
        const entity = { ...this.entity }
        entity.userId = val
        entity.groupId = null
        this.$emit('commit', entity)
      },
      get(): string | null {
        return this.entity.userId || null
      },
    },
    groupValue: {
      set(val: string) {
        const entity = { ...this.entity }
        entity.groupId = val
        entity.userId = null
        this.$emit('commit', entity)
      },
      get(): string | null {
        return this.entity.groupId || null
      },
    },
  },
  watch: {
    selectedField(val, prevVal) {
      if (val === null) {
        this.selectedField = prevVal === 'groupId' ? 'userId' : 'groupId'
      } else if (val === 'all') {
        this.userValue = null
      }
    },
  },
  async mounted() {
    const { groupId, userId } = this.entity
    const id = groupId || userId
    const type = groupId ? 'Group' : 'User'
    id && this.names.set(id, await this.fetchEntityTitle(id, type))
    this.namesFetched = true
  },
  methods: {
    onSelectUser({ value: { id, fullName } }: { value: User }) {
      this.names.set(id, fullName)
      this.userValue = id
    },
    onSelectGroup({ value: { id, title } }: { value: Group }) {
      this.names.set(id, title)
      this.groupValue = id
    },
    async fetchEntityTitle(id: string, type: string) {
      const { entity } = await this.api.getEntity<User | Group>({
        id,
        type,
      })
      return entity.title || ('fullName' in entity && entity.fullName) || ''
    },
  },
})
