














import Vue from 'vue'
import { get, set } from 'lodash'
import { mapStateTyped } from '@/store'

export default Vue.extend({
  name: 'InputField',
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {}
  },
  computed: {
    ...mapStateTyped(['strictRegEx', 'isNewEntity']),
    localValue: {
      set(val: string) {
        const entity = { ...this.entity }
        set(entity, this.options.title, val.replace(this.strictRegEx, ''))
        this.$emit('commit', entity)
      },
      get(): String {
        return String(get(this.entity, this.options.title) || '')
      },
    },
    disabled(): boolean {
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      )
    },
  },
})
