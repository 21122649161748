



import Vue from 'vue'
import { get } from 'lodash'
import { mapGettersTyped } from '@/store'

type ErrorObject = {
  fieldName?: string
  validationKey: string
  hasError: boolean
  params: { attribute: string }
  $dirty: boolean
  $error: unknown
  $invalid: boolean
}

export default Vue.extend({
  props: {
    validator: Object,
    attributes: Object,
    onValidate: Function,
  },
  computed: {
    ...mapGettersTyped(['te']),
    messages(): Record<string, string> {
      return {
        required: `${this.te('validations.requiredField')}`,
        schemaRequired: `${this.te('validations.requiredField')}`,
        schemaMinLength: `${this.te('validations.schemaMinLength')}`,
        schemaMaxLength: `${this.te('validations.schemaMaxLength')}`,
        schemaMinItems: `${this.te('validations.schemaMinItems')}`,
        schemaMaxItems: `${this.te('validations.schemaMaxItems')}`,
        schemaMaximum: `${this.te('validations.schemaMaximum')}`,
        schemaMinimum: `${this.te('validations.schemaMinimum')}`,
        schemaUniqueItems: `${this.te('validations.schemaUniqueItems')}`,
        schemaType: `${this.te('validations.schemaType')}`,
        schemaAdditionalProperties: `${this.te('validations.schemaType')}`,
        schemaPattern: `${this.te('validations.schemaPattern')}`,
        durationPattern: `${this.te('validations.durationPattern')}`,
        isReferenceValid: `${this.te('validations.isReferenceValid')}`,
        schemaBetween: `${this.te('validations.schemaBetween')}`,
      }
    },
    localErrors(): ErrorObject[] {
      return this.flattenValidatorObjects(this.validator)
    },
    errorMessages(): string[] {
      return Array.from(
        new Set(
          this.localErrors
            .filter(({ hasError }) => hasError)
            .map((error) => this.getErrorString(error)),
        ),
      )
    },
  },
  watch: {
    errorMessages(val) {
      this.onValidate(val.length === 0, val)
    },
  },
  methods: {
    flattenValidatorObjects(validator: any, fieldName?: string): ErrorObject[] {
      // loop the validator objects
      return Object.entries(validator)
        .filter(({ 0: key }) => !key.startsWith('$') || key === '$each')
        .reduce((errors, [key, value]) => {
          if (typeof value === 'object') {
            const nestedValidatorName =
              key === '$each' || !isNaN(parseInt(key))
                ? fieldName
                : fieldName
                ? `${fieldName}.${key}`
                : key
            return errors.concat(
              this.flattenValidatorObjects(value, nestedValidatorName),
            )
          }
          const params = Object.assign({}, validator.$params[key])
          delete params.type
          errors.push({
            fieldName: fieldName,
            validationKey: key,
            hasError: !value,
            params,
            $dirty: validator.$dirty,
            $error: validator.$error,
            $invalid: validator.$invalid,
          })
          return errors
        }, [] as ErrorObject[])
    },
    getErrorString({ validationKey: key, params, fieldName }: ErrorObject) {
      if (!fieldName) return ''
      const msg = get(this.messages, key, false)
      const _fieldName = fieldName.replace('.', '')
      if (this.attributes && this.attributes[_fieldName]) {
        params.attribute = this.attributes[_fieldName]
      }
      if (!msg) {
        return key
      }
      if (typeof msg !== 'string') {
        throw new TypeError(
          `Expected a string in the first argument, got ${typeof msg}`,
        )
      }

      if (typeof params !== 'object') {
        throw new TypeError(
          `Expected an Object/Array in the second argument, got ${typeof params}`,
        )
      }
      const regx = /{(.*?)}/g
      return msg.replace(regx, (_, key) => get(params, key, _fieldName))
    },
  },
})
