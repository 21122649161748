















import Vue from 'vue'
import InputNumber from 'primevue/inputnumber'
import { get, set } from 'lodash'
import { mapStateTyped } from '@/store'

export default Vue.extend({
  name: 'NumberField',
  components: {
    InputNumber,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {}
  },
  computed: {
    ...mapStateTyped(['isNewEntity']),
    disabled(): boolean {
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      )
    },
    localValue: {
      set(val: number) {
        const entity = { ...this.entity }
        set(entity, this.options.title, Number(val))
        this.$emit('commit', entity)
      },
      get(): number | null {
        return Number(get(this.entity, this.options.title)) || null
      },
    },
  },
})
