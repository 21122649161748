var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FieldLabel',{attrs:{"options":_vm.options}},[_c('div',{staticClass:"p-d-flex"},[_c('span',{staticClass:"p-input-raised p-mr-1",staticStyle:{"width":"100%"}},[_c('InputText',{class:{
          'p-error': !_vm.validCoordinates,
        },staticStyle:{"width":"100%"},attrs:{"type":"text","data-cy":((_vm.entity.type) + "_" + (_vm.options.title)),"aria-describedby":(_vm._uid + "coordinates"),"disabled":_vm.disabled},model:{value:(_vm.localValueInputed),callback:function ($$v) {_vm.localValueInputed=$$v},expression:"localValueInputed"}}),(!_vm.validCoordinates)?_c('small',{staticClass:"p-invalid",staticStyle:{"color":"var(--error-color)"},attrs:{"id":(_vm._uid + "coordinates")},domProps:{"textContent":_vm._s(((_vm.te('form.format')) + " 99.999999, 99.999999"))}}):_vm._e()],1),_c('Button',{style:({ lineHeight: '1.2' }),attrs:{"type":"button","icon":"mdi mdi-18px mdi-map","data-cy":((_vm.entity.type) + "_" + (_vm.options.title) + "_map"),"disabled":_vm.disabled},on:{"click":_vm.toggleOverlayPanel}})],1),_c('OverlayPanel',{ref:"OverlayPanel",staticClass:"fullheight-overlay",attrs:{"position":"full","appendTo":"body"}},[_c('Dialog',{style:({ width: '1024px', height: '90vh', overflow: 'hidden' }),attrs:{"visible":_vm.showMapDialog,"position":"center","header":_vm.location,"closeOnEscape":false,"modal":true},on:{"update:visible":function($event){_vm.showMapDialog=$event},"hide":_vm.toggleOverlayPanel},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticStyle:{"display":"flex"}},[_c('Button',{attrs:{"label":_vm.te('button.save'),"data-cy":((_vm.entity.type) + "_" + (_vm.options.title) + "_save"),"disabled":_vm.disabled},on:{"click":function($event){_vm.localValue = _vm.location
              _vm.showMapDialog = false}}}),_c('Button',{staticClass:"p-button-secondary p-button-outlined",attrs:{"label":_vm.te('button.cancel'),"data-cy":((_vm.entity.type) + "_" + (_vm.options.title) + "_cancel")},on:{"click":function($event){_vm.showMapDialog = false}}})],1)]},proxy:true}])},[_c('div',{staticStyle:{"position":"relative"}},[_c('MapTalksMap',{attrs:{"mapView":_vm.mapView,"options":{ centerCross: true, centerByClick: true }},on:{"viewChange":_vm.viewChange}},[_c('TLayer',{attrs:{"layerName":(_vm._uid + "marker_layer")}},[_c('TMarker',{attrs:{"location":_vm.locationToCoordinates(_vm.location),"zoomOnClick":"","animationDuration":300,"dy":-36}},[_c('div',[_c('MarkerCircle',{attrs:{"color":(_vm.entity.icon && _vm.entity.icon.color) || '#005AD1',"haveLeg":""}},[_c('div',{staticClass:"icon"},[_c('i',{class:("mdi mdi-24px " + ((_vm.entity.icon &&
                          _vm.entity.icon.iconClass) ||
                          'mdi-video'))})])])],1)]),_c('TMarker',{attrs:{"location":_vm.locationToCoordinates(_vm.localValue),"zoomOnClick":"","animationDuration":300,"dy":-36}},[_c('div',{staticStyle:{"opacity":"50%"}},[_c('MarkerCircle',{attrs:{"color":(_vm.entity.icon && _vm.entity.icon.color) || '#005AD1',"haveLeg":""}},[_c('div',{staticClass:"icon"},[_c('i',{class:("mdi mdi-24px " + ((_vm.entity.icon &&
                          _vm.entity.icon.iconClass) ||
                          'mdi-video'))})])])],1)])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }