





import Vue from 'vue'
import { getWidgetMap } from './mapStore'
// @ts-ignore
import { VectorLayer } from 'maptalks'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'TLayer',
  props: {
    layerName: String,
    options: Object,
    visible: Boolean,
  },
  data() {
    return {
      layerComponent: {} as any,
    }
  },
  computed: {
    ...mapState(['widgetUUID']),
  },
  beforeMount() {
    const parentMap = getWidgetMap(this.widgetUUID)
    this.layerComponent = new VectorLayer(this.layerName, [], {
      ...this.options,
      zIndex: 10,
    }).addTo(parentMap)
  },
  beforeDestroy() {
    this.layerComponent.remove()
  },
})
