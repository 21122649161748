



















import Vue from 'vue'
import { get, set } from 'lodash'
import { mapStateTyped } from '@/store'

export default Vue.extend({
  name: 'JSONField',
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      isValidJSON: true,
    }
  },
  computed: {
    ...mapStateTyped(['isDarkTheme', 'isNewEntity']),
    disabled(): boolean {
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      )
    },
    localValue: {
      set(val) {
        try {
          const entity = { ...this.entity }
          set(
            entity,
            this.options.title,
            this.options.isArray
              ? Object.values(JSON.parse(JSON.stringify(val)))
              : val,
          )
          this.$emit('commit', entity)
          this.isValidJSON = true
        } catch {
          this.isValidJSON = false
        }
      },
      get(): Object {
        return get(this.entity, this.options.title)
      },
    },
  },
  methods: {},
})
