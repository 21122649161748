































import Vue from 'vue'
import {
  Map as Maptalks,
  // eslint-disable-next-line no-unused-vars
  Layer,
  VectorLayer,
  GroupTileLayer,
  // @ts-ignore
} from 'maptalks'
import { mapState } from 'vuex'
import { layerFactory } from './layersFactory'
import LayersMenu from './LayersMenu.vue'
import { setWidgetMap } from './mapStore'

const defaultify = (str: string, isDark: boolean) =>
  str === 'default' ? `default${isDark ? 'Dark' : 'Light'}` : str

export default Vue.extend({
  name: 'MapCanvas',
  components: {
    LayersMenu,
  },
  props: {
    mapObject: Object,
    mapView: Object,
    options: Object,
    height: String,
    width: String,
    centerCross: Boolean,
    centerByClick: Boolean,
  },
  data: () => {
    const savedLayerName = localStorage.getItem('savedLayerName')
    return {
      urlTemplateLight:
        'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      urlTemplateDark:
        'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
      isClickedAndHolded: true,
      baseTileLayer: {} as any,
      currentLayerName: savedLayerName || 'default',
      maxZoom: 23,
      minZoom: 5,
    }
  },
  computed: {
    ...mapState(['isDarkTheme', 'spaProps', 'widgetUUID']),
  },
  watch: {
    isDarkTheme(val) {
      if (this.currentLayerName !== 'default') return
      if (val) {
        this.static.layersMap.defaultLight.hide()
        this.static.layersMap.defaultDark.show()
      } else {
        this.static.layersMap.defaultLight.show()
        this.static.layersMap.defaultDark.hide()
      }
    },
    currentLayerName(val, prevVal) {
      this.static.layersMap?.[defaultify(prevVal, this.isDarkTheme)]?.hide()
      this.static.layersMap?.[defaultify(val, this.isDarkTheme)]?.show()
    },
  },
  created() {
    this.static = {
      parentMap: {} as Maptalks,
      layersMap: {} as Record<string, Layer>,
      baseTileLayer: new GroupTileLayer('base', []),
    } as const
    window.addEventListener('keydown', this.plusMinusClickHandler)
  },

  mounted() {
    const resolutions = []
    const d = 2 * 6378137 * Math.PI
    for (let i = 0; i < this.maxZoom + 1; i++) {
      resolutions[i] = d / (256 * Math.pow(2, i))
    }
    const { center, zoom, bearing } = this.mapView
    const contstr = `${this._uid}_map-container`

    this.static.parentMap = new Maptalks(contstr, {
      center,
      pitch: 0,
      zoom: zoom ?? 14,
      bearing: bearing ?? 0,
      maxPitch: 65,
      spatialReference: {
        projection: 'EPSG:3857',
      },
      resolutions: resolutions,
      fullExtent: {
        top: 6378137 * Math.PI,
        left: -6378137 * Math.PI,
        bottom: -6378137 * Math.PI,
        right: 6378137 * Math.PI,
      },
      hitDetect: false,
      fog: false,
      centerCross: this.centerCross,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
    })
    setWidgetMap(this.widgetUUID, this.static.parentMap)
    if (this.spaProps.disableDefaultLayers !== false) {
      layerFactory.tile({
        name: 'defaultLight',
        options: {
          visible: this.currentLayerName === 'default' && !this.isDarkTheme,
          urlTemplate:
            this.spaProps.urlTemplateLight ||
            'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
        },
        map: this.static.parentMap,
        layersRegistry: this.static.layersMap,
      })
      layerFactory.tile({
        name: 'defaultDark',
        options: {
          visible: this.currentLayerName === 'default' && this.isDarkTheme,
          urlTemplate:
            this.spaProps.urlTemplateDark ||
            'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
        },
        map: this.static.parentMap,
        layersRegistry: this.static.layersMap,
      })
    }

    if (this.spaProps.layers?.length > 0) {
      for (const layer of this.spaProps.layers) {
        layerFactory?.[layer.type]?.({
          name: layer.title,
          options: {
            visible: this.currentLayerName === layer.title,
            ...layer.options,
          },
          map: this.static.parentMap,
          layersRegistry: this.static.layersMap,
        })
      }
    }

    if (this.centerByClick) {
      this.static.parentMap.on('click', (center: any) => {
        const currentView = this.static.parentMap.getView()
        this.static.parentMap.animateTo(
          { ...currentView, center: center.coordinate },
          {
            duration: 200,
          },
        )
      })
    }
    this.static.parentMap.on('viewchange', () => {
      this.$emit('update:mapView', this.static.parentMap.getView())
      this.$emit('viewChange', this.static.parentMap.getCenter())
    })
    new VectorLayer('markers_layer').addTo(this.static.parentMap)
    this.$emit('mounted')
  },
  methods: {
    zoomIn() {
      this.static.parentMap.zoomIn()
    },
    zoomOut() {
      this.static.parentMap.zoomOut()
    },
    plusMinusClickHandler(e: KeyboardEvent) {
      if (e.key === '+') {
        this.zoomIn()
      }
      if (e.key === '-') {
        this.zoomOut()
      }
    },
  },
})
