









































































import Vue from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputField from './InputField.vue'
import { arrayMap } from '@/components/fieldsMap'
import { get, set } from 'lodash'
import { mapGettersTyped } from '@/store'

type FieldOptions = {
  title: string
  type: string
  class: string
  uniqueFields: string[]
  fields: { title: string; type: 'enum' | 'string' | 'number' }[]
}

export default Vue.extend({
  name: 'ArrayField',
  components: {
    DataTable,
    Column,
  },
  props: {
    entity: Object,
    entityType: String,
    options: Object as () => FieldOptions,
  },
  data() {
    return {
      InputField,
      componentMap: arrayMap,
    }
  },
  computed: {
    ...mapGettersTyped(['te']),
    context(): any {
      if (Array.isArray(this.options.uniqueFields)) {
        return {
          excludeOptions: this.options.uniqueFields.reduce(
            (acc, fieldTitle) => {
              return {
                ...acc,
                [fieldTitle]: this.localValue.map((e: any) => e[fieldTitle]),
              }
            },
            {},
          ),
        }
      } else {
        return {}
      }
    },
    localValue: {
      set(val: any) {
        const entity = { ...this.entity }
        set(entity, this.options.title, val)
        this.$emit('commit', entity)
      },
      get(): string[] {
        return get(this.entity, this.options.title) || []
      },
    },
  },
  methods: {
    commit(field: any, value: any, title: string) {
      if (field.data[title] !== value[title]) {
        field.data[title] = value[title]
        const entity = { ...this.entity }
        this.$emit('commit', entity)
      }
    },
    addElement() {
      const entity = { ...this.entity }
      entity[this.options.title] = Array.isArray(entity[this.options.title])
        ? [
            ...entity[this.options.title],
            this.options.fields.reduce((acc, val) => ({
              ...acc,
              [val.title]: undefined,
            })),
          ]
        : [{}]
      this.$emit('commit', entity)
    },
    deleteElement({ index }: { index: number }) {
      const entity = { ...this.entity }
      entity[this.options.title].splice(index, 1)
      this.$emit('commit', entity)
    },
  },
})
