
























import Vue from 'vue'
import { capitalize } from '@/utils'
import { get } from 'lodash'
import { mapGettersTyped, mapStateTyped } from '@/store'

export default Vue.extend({
  name: 'FieldLabel',
  inject: ['$v'],
  props: {
    options: Object,
    fieldClass: { type: String, default: '' },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGettersTyped(['te']),
    ...mapStateTyped(['entityType']),
    isValid(): boolean {
      const validation = get(this.$v.localEntity, this.options.title)
      return validation ? !validation.$invalid : true
    },
  },
  methods: {
    capitalize,
  },
})
