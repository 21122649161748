




import Vue from 'vue'
import FieldsList from './components/FieldsList.vue'
import { repositoryGetter } from '@netvision/lib-api-repo'
import { uuid } from './utils'
import initialStore, { mapMutationsTyped, mapStateTyped } from './store'
import Vuex from 'vuex'

export default Vue.extend({
  name: 'App',
  components: {
    FieldsList,
  },
  data() {
    const props = this.$parent?.props
    if (props.entity) {
      delete props.entity.$groupKey
      delete props.entity.$index
    } else {
      props.entity = {
        type: props.entityType,
      }
    }
    return {
      options: {
        ...props,
      } as WidgetProps | null,
      required: [],
      showFields: true,
      entityTypeMetadata: {} as EntityMetadata,
      themeUnsubscribe: () => {},
      observerUnsubscribe: () => {},
    }
  },
  computed: {
    ...mapStateTyped(['locale']),
  },
  beforeCreate() {
    this.$store = new Vuex.Store(initialStore())
  },
  beforeMount() {
    if (this.options === null) {
      return
    }
    this.reset()
    this.options &&
      this.addLocale([this.options.entityType, this.options.locale])

    this.$store.commit('reset')
    this.setValue(['spaProps', this.options])
    this.setValue(['api', repositoryGetter(this.$parent?.props?.lib?.name)])
    this.setValue(['widgetUUID', uuid()])
    const themeEl = document.getElementById('theme') as HTMLLinkElement & {
      setTheme: () => void
    }
    this.setValue(['spaParent', this.$parent])
    this.$parent?.props.defaultMapLocation &&
      this.setValue([
        'defaultMapLocation',
        this.$parent?.props.defaultMapLocation,
      ])
    this.setValue(['entityType', this.options?.entityType])
    this.setValue(['isNewEntity', !!this.options.entity.id])
    this.setValue([
      'isDarkTheme',
      themeEl.getAttribute('theme-name') === 'dark',
    ])
    const themeSubscribe = (func: (newValue: boolean) => void) => {
      const listener = (e: any) => func(e.detail === 'dark')
      themeEl.addEventListener('update', listener)
      return () => themeEl.removeEventListener('update', listener)
    }
    this.themeUnsubscribe = themeSubscribe((newValue) => {
      this.setValue(['isDarkTheme', newValue])
    })
    typeof this.options.subscribe === 'function' &&
      (this.observerUnsubscribe = this.options.subscribe((newProps) => {
        const currentOptions = this.options

        if ('locale' in newProps) {
          const entityType = newProps?.entityType || currentOptions?.entityType
          entityType &&
            newProps.locale &&
            this.addLocale([entityType, newProps.locale])
        }
        if (
          currentOptions?.entityType !== newProps.entityType ||
          currentOptions?.entity?.id !== newProps?.entity?.id
        ) {
          newProps.entityType &&
            this.setValue(['entityType', newProps.entityType])

          this.showFields = false
        }
        this.options = { ...currentOptions, ...newProps } as WidgetProps
        setTimeout(() => {
          this.showFields = true
        })
      }))
  },
  beforeDestroy() {
    this.observerUnsubscribe()
    this.themeUnsubscribe()
  },
  methods: {
    ...mapMutationsTyped(['setValue', 'addLocale', 'reset']),
  },
})
