




















import Vue from 'vue'
import MultiSelect from 'primevue/multiselect'
import { get, set } from 'lodash'
import { mapGettersTyped, mapStateTyped } from '@/store'

export default Vue.extend({
  name: 'ScopesField',
  components: {
    MultiSelect,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      scopes: [] as any,
    }
  },
  computed: {
    ...mapGettersTyped(['te']),
    ...mapStateTyped(['api', 'isNewEntity']),
    localValue: {
      set(val: string) {
        const entity = { ...this.entity }
        set(entity, this.options.title, val)
        this.$emit('commit', entity)
      },
      get(): Object {
        return get(this.entity, this.options.title) || ''
      },
    },
    disabled(): boolean {
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      )
    },
    entityType(): string {
      return this.entity.entityType
    },
  },
  watch: {
    async entityType() {
      this.scopes = await this.fetchScopes()
      const entity = { ...this.entity, [this.options.title]: [] }
      this.$emit('commit', entity)
    },
  },
  async mounted() {
    this.scopes = await this.fetchScopes()
  },
  methods: {
    getOptionValue(
      options: any[],
      val: string[],
      optionLabel = 'title',
      optionValue = 'id',
    ) {
      return val && options.length > 0
        ? val
            .map((e) => {
              return (options.find((el) => el[optionValue] === e) || {
                [optionLabel]: () => {
                  return this.te('form.unknownValue')
                },
              })[optionLabel]
            })
            .join(', ') || this.te('form.choose')
        : this.te('form.choose')
    },
    fetchScopes() {
      return this.api
        .getEntitiesList({
          limiter: {
            type: 'PermissionScope',
            limit: 1000,
            keyValues: true,
          },
          filter: {
            q: [
              {
                key: 'entityType',
                operator: '==',
                value: `${this.entityType}`,
              },
            ],
          },
        })
        .then(({ results }: any) => {
          return results
        })
    },
  },
})
