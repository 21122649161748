













import Vue from 'vue'
// @ts-ignore
import MapCanvas from './MapCanvas.vue'

export default Vue.extend({
  name: 'MaptalksMap',
  components: {
    MapCanvas,
  },
  props: {
    mapView: Object,
    options: Object,
  },
  data() {
    return {
      localMapView: this.mapView,
      mounted: false,
    }
  },
  watch: {
    localMapView(val) {
      this.$emit('update:mapView', val)
    },
  },
})
