

















import Vue from 'vue'
import { get, set } from 'lodash'
import { mapStateTyped } from '@/store'

const SECONDS = 1
const MINUTES = SECONDS * 60
const HOURS = MINUTES * 60
const DAYS = HOURS * 24
const WEEKS = DAYS * 7
const MONTH = DAYS * 30
const YEAR = DAYS * 365
const UNITS = new Map([
  ['y', YEAR],
  ['M', MONTH],
  ['w', WEEKS],
  ['d', DAYS],
  ['h', HOURS],
  ['m', MINUTES],
  ['s', SECONDS],
])
const MATCH_STRING_REG = /^((\d+y\s*)?(\s*)?(\d+M\s*)?(\s*)?(\d+w\s*)?(\s*)?(\d+d\s*)?(\s*)?(\d+h\s*)?(\s*)?(\d+m\s*)?(\s*)?(\d+s\s*)?)$/

export default Vue.extend({
  name: 'DurationField',
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      dirtyDuration: '',
    }
  },
  computed: {
    ...mapStateTyped(['isNewEntity']),
    localValue: {
      set(val: number | string) {
        const entity = { ...this.entity }
        set(entity, this.options.title, val)
        this.$emit('commit', entity)
      },
      get(): number | null {
        return Number(get(this.entity, this.options.title)) || null
      },
    },
    disabled(): boolean {
      return (
        (this.options.disabledForEdit &&
          // @ts-ignore
          !this.isNewEntity) ||
        !(this.options.editable === undefined || this.options.editable)
      )
    },
  },
  watch: {
    dirtyDuration(val: string) {
      this.localValue =
        val.trim() && MATCH_STRING_REG.test(val)
          ? val
              .split(' ')
              .filter((section) => !!section)
              .map((section) => section.trim())
              .reduce((acc, val) => {
                const num = Number(val.match(/^\d+/))
                const symbol = val.match(/\w$/)
                const multiplier = UNITS.get(`${symbol}`)
                if (num && symbol && multiplier) {
                  return acc + multiplier * num
                }
                return acc
              }, 0)
          : val
    },
  },
  mounted() {
    if (this.localValue) {
      let durationString = ''
      let leftTime =
        (typeof this.localValue === 'number' && this.localValue) || 0
      for (const [symbol, seconds] of UNITS) {
        const quotient = leftTime / seconds
        if (quotient >= 1) {
          leftTime = leftTime - Math.floor(quotient) * seconds
          durationString = durationString
            ? `${durationString} ${Math.floor(quotient)}${symbol}`
            : `${Math.floor(quotient)}${symbol}`
        }
      }
      this.dirtyDuration = durationString
    }
  },
})
