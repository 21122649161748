










































































































import Vue from 'vue'
import { mapState } from 'vuex'
import DataView from 'primevue/dataview'
import MarkerCircle from '../common/MarkerCircle.vue'
import InputText from 'primevue/inputtext'
import ColorPicker from 'primevue/colorpicker'

// @ts-ignore
import { iconsList } from './iconsList'
import { get, set } from 'lodash'
import { mapGettersTyped } from '@/store'

type Icon = {
  iconClass: string
  color: string
}

export default Vue.extend({
  name: 'ImageField',
  components: {
    MarkerCircle,
    DataView,
    InputText,
    ColorPicker,
  },
  props: {
    entity: Object,
    options: Object,
  },
  data() {
    return {
      iconsDialog: true,
      iconsList,
      search: '',
    }
  },
  computed: {
    ...mapGettersTyped(['te']),
    ...mapState(['isDarkTheme']),
    filteredIcons(): string[] {
      return iconsList.filter((iconClass: string) =>
        iconClass.includes(this.search.toLowerCase()),
      )
    },
    color: {
      set(val: string) {
        if (!/^#/.test(val)) {
          val = '#' + val
        }
        if (/^#(?:[0-9a-fA-F]{3}){1,2}$/.test(val)) {
          this.debounce(() => {
            this.localValue = { ...this.localValue, color: val }
          }, 200)
        }
      },
      get(): string {
        return this.localValue.color
      },
    },
    localValue: {
      set(val: Icon) {
        const entity = { ...this.entity }
        set(entity, this.options.title, val)
        this.$emit('commit', entity)
      },
      get(): Icon {
        return (
          get(this.entity, this.options.title) || {
            iconClass: 'mdi-video',
            color: '#005AD1',
          }
        )
      },
    },
  },
  beforeMount() {
    if (this.entity[this.options.title] === undefined) {
      this.$emit('commit', {
        iconClass: 'mdi-video',
        color: '#3c72ff',
      })
    }
  },
  methods: {
    toggleOverlayPanel(): void {
      // @ts-ignore
      this.$refs?.OverlayPanel.toggle()
      this.iconsDialog = !this.iconsDialog
    },
    changeIcon(icon: string) {
      this.localValue = { ...this.localValue, iconClass: icon }
      this.iconsDialog = false
    },
    debounce(callback: Function, timeout: number) {
      clearTimeout(this.$data.inputTimer)
      this.$data.inputTimer = setTimeout(callback, timeout)
    },
  },
})
