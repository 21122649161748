































import Vue from 'vue'
import AutoComplete from 'primevue/autocomplete'
import { mapGettersTyped, mapStateTyped } from '@/store'
import { IRequestFilter } from '@netvision/lib-api-repo/dist/src/types'
const strictRegEx = /[^a-zA-Z0-9а-яА-Я-.,\s]/g
export default Vue.extend({
  name: 'AutocompleteField',
  components: {
    AutoComplete,
  },
  props: {
    value: String,
    editable: Boolean,
    entityType: String,
    searchField: String,
    label: String,
    qFilter: Array as () => IRequestFilter['q'],
  },
  data() {
    return {
      localValue: this.value,
      suggestions: [] as any[],
    }
  },
  computed: {
    ...mapGettersTyped(['te']),
    ...mapStateTyped(['strictRegEx', 'api']),
  },
  watch: {
    localValue(val: string) {
      const clearedVal = val.replace(strictRegEx, '')
      if (this.localValue.length !== clearedVal.length) {
        this.localValue = clearedVal
      }
    },
    value(val) {
      this.localValue = val
    },
  },
  methods: {
    onSelect(event: any, eventName: string) {
      this.localValue = ''
      this.$emit(eventName, event)
    },

    async fetchEntities(): Promise<void> {
      const qFilter = this.qFilter?.map(item => {
        item.operator = item.operator || '~='
        return item
      })
      const baseFilter = {
          filter: {
            q: [
              ...(qFilter || []),
              ...(this.localValue !== ''
                ? [{
                  key: this.searchField,
                  value: this.localValue,
                  operator: '~=' as const,
                }]
                : [])
            ]
          }
      } as IRequestFilter

      const { results } = await this.api.getEntitiesList({
        limiter: {
          type: this.entityType,
          limit: 25,
        },
        ...(this.localValue !== '' || qFilter?.length ? baseFilter : {}),
      })
      this.suggestions = results
    },
  },
})
