import './set-public-path'
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import Vuex from 'vuex'
import Vuelidate from 'vuelidate'
import Tooltip from 'primevue/tooltip'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Textarea from 'primevue/textarea'
import ProgressBar from 'primevue/progressbar'
import FieldLabel from '@/components/common/FieldLabel.vue'
import DeleteDialog from '@/components/common/DeleteDialog.vue'
import FiealdsList from '@/components/FieldsList.vue'
import MultiSelect from 'primevue/multiselect'
import OverlayPanel from 'primevue/overlaypanel'

// @ts-ignore
import VJsoneditor from 'v-jsoneditor/src/index'
// @ts-ignore
import VueVuelidateJsonschema from 'vue-vuelidate-jsonschema'
import App from './App.vue'

Vue.directive('tooltip', Tooltip)
Vue.use(Vuex)
Vue.component('MultiSelect', MultiSelect)
Vue.component('InputText', InputText)
Vue.component('Button', Button)
Vue.component('Dialog', Dialog)
Vue.component('TabView', TabView)
Vue.component('TabPanel', TabPanel)
Vue.component('Textarea', Textarea)
Vue.component('ProgressBar', ProgressBar)
Vue.component('FieldLabel', FieldLabel)
Vue.component('DeleteDialog', DeleteDialog)
Vue.component('FiealdsList', FiealdsList)
Vue.component('VJsoneditor', VJsoneditor)
Vue.component('OverlayPanel', OverlayPanel)
Vue.use(VueVuelidateJsonschema)
Vue.use(Vuelidate)

Vue.config.productionTip = false

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h: any) => h(App),
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
