












import { mapGettersTyped, mapStateTyped } from '@/store'
import Vue from 'vue'
export default Vue.extend({
  name: 'AdminDialogButton',
  props: {
    entity: Object as () => IEntity,
    options: Object,
    context: Object,
  },
  data() {
    return {
      showAdiminDialog: false,
      unmount: () => {},
    }
  },
  computed: {
    ...mapGettersTyped(['te']),
    ...mapStateTyped(['spaParent']),
  },
  watch: {
    showAdiminDialog(val) {
      if (val) {
        this.mountDialog()
      } else {
        this.unmount()
      }
    },
  },
  destroyed() {
    this.unmount()
  },
  methods: {
    mountDialog() {
      const ref = this.$refs.AdminDialog
      // @ts-ignore
      const adminDialog = this.spaParent.areas.find(
        (e: any) => e.name === 'create-permission',
      )
      if (adminDialog !== undefined) {
        // @ts-ignore
        this.unmount = this.spaParent.mountChildren(
          ref,
          adminDialog.children.map((e: any) => {
            return {
              ...e,
              // @ts-ignore
              areas: this.spaParent.areas,
              props: {
                ...e.props,
                entityType: this.entity.type,
                entity: this.entity,
                onClose: (e: any) => {
                  this.showAdiminDialog = false
                  this.context.onClose(e)
                },
              },
            }
          }),
        )
      }
    },
  },
})
