import InputField from './fields/InputField.vue'
import JSONField from './fields/JSONField.vue'
import ArrayField from './fields/ArrayField.vue'
import URLField from './fields/URLField.vue'
import NumberField from './fields/NumberField.vue'
import ToggleField from './fields/ToggleField.vue'
import EnumField from './fields/EnumField.vue'
import TextField from './fields/TextField.vue'
import ScopesField from './fields/ScopesField.vue'
import UsersField from './fields/UsersField.vue'
import TypeField from './fields/TypeField.vue'
import GroupsField from './fields/GroupsField.vue'
import CoordinatesField from './fields/CoordinatesField.vue'
import ImageField from './fields/ImageField.vue'
import IconField from './fields/IconField.vue'
import ReferenceField from './fields/ReferenceField.vue'
import GroupOrUserField from './fields/GroupOrUserField.vue'
import AddressField from './fields/AddressField.vue'
import AdminDialogButton from './fields/AdminDialogButton.vue'
import ScheduleField from './fields/ScheduleField.vue'
import SetDefault from './fields/SetDefault.vue'
import MultiReferenceField from './fields/MultiReferenceField.vue'
import DurationField from './fields/DurationField.vue'
import { VueConstructor } from 'vue'

export const arrayMap = {
  string: InputField,
  enum: EnumField,
  number: NumberField,
}

export default {
  string: InputField,
  text: TextField,
  scopes: ScopesField,
  object: JSONField,
  url: URLField,
  number: NumberField,
  array: ArrayField,
  boolean: ToggleField,
  enum: EnumField,
  users: UsersField,
  entityType: TypeField,
  groups: GroupsField,
  image: ImageField,
  icon: IconField,
  coordinates: CoordinatesField,
  groupOrUser: GroupOrUserField,
  reference: ReferenceField,
  address: AddressField,
  adminDialog: AdminDialogButton,
  schedule: ScheduleField,
  setDefault: SetDefault,
  multiReference: MultiReferenceField,
  duration: DurationField,
} as Record<FieldType, VueConstructor>
