












import Vue from 'vue'
import { findParentLayer } from '@/utils'
// @ts-ignore
import { ui, Marker, Coordinate, animation } from 'maptalks'
import { mapState } from 'vuex'
import { getWidgetMap } from './mapStore'

export default Vue.extend({
  name: 'TMarker',
  props: {
    location: [Array, Object],
    visible: { type: Boolean, required: false, default: undefined },
    centerizeOnClick: Boolean,
    zoomOnClick: Boolean,
    animationDuration: Number,
    draggable: Boolean,
    editing: Boolean,
    dx: Number,
    dy: Number,
  },
  data: () => ({
    marker: {} as any,
    markerGeometry: {} as any,
    showPopup: false,
    popup: {} as any,
    animationDurationLocal: 0,
    lastLocation: {} as any,
  }),
  computed: {
    ...mapState(['widgetUUID']),
    hasPopupSlot(): Boolean {
      return !!this.$slots.popup
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.marker.show()
        if (this.hasPopupSlot) {
          this.markerGeometry.show()
        }
      } else {
        this.marker.hide()
        if (this.hasPopupSlot) {
          this.markerGeometry.hide()
        }
      }
    },
    location(first, prev) {
      this.moveLocation(prev, first, false)
    },
  },
  created() {
    this.static = {
      parentMap: {} as any,
    }
  },
  mounted() {
    this.animationDurationLocal = this.animationDuration
      ? this.animationDuration
      : 200
    this.marker = new ui.UIMarker(this.location, {
      draggable: this.draggable,
      dy: this.dy || 0,
      dx: this.dx || 0,
      animationDuration: this.animationDurationLocal,
      content: document.getElementById(`${this._uid}_marker`),
    })
    if (this.hasPopupSlot) {
      const layer = findParentLayer(this)
      this.markerGeometry = new Marker(this.location, {
        visible: this.visible === undefined || this.visible,
        symbol: {
          markerType: 'ellipse',
          markerFillOpacity: 0,
          markerLineWidth: 0,
          markerWidth: 46,
          markerHeight: 46,
          markerDy: -52,
        },
      }).addTo(layer)
      this.popup = new ui.InfoWindow({
        single: true,
        autoOpenOn: 'click',
        autoCloseOn: 'click',
        animation: 'fade',
        custom: false,
        content: document.getElementById(`${this._uid}_popup`),
      })
      this.popup.addTo(this.markerGeometry)
    }
    this.static.parentMap = getWidgetMap(this.widgetUUID)
    this.marker.addTo(this.static.parentMap)
    if (this.draggable) {
      this.marker.on('dragstart', () => {
        let isDragged = false
        setTimeout(() => {
          isDragged = true
        }, 200)
        this.marker.once('dragend', ({ coordinate: { x, y } }: any) => {
          if (isDragged) {
            const position = [x, y]
            this.$emit('dragend', position)
          }
        })
      })
    }
    if (this.visible === undefined || this.visible) {
      setTimeout(() => {
        this.marker.show()
      }, this.animationDurationLocal)
    } else {
      this.marker.hide()
    }
  },
  destroyed() {
    this.marker.hide()
    setTimeout(() => {
      this.marker.remove()
    }, this.animationDurationLocal)
    if (this.hasPopupSlot) {
      this.markerGeometry.remove()
    }
  },
  methods: {
    moveLocation(from: any, to: any, hide = false) {
      const start = new Coordinate(from)
      const stop = new Coordinate(to)
      const offset = stop.sub(start)
      if (offset.x !== 0 || offset.y !== 0) {
        const player = animation.Animation.animate(
          {},
          {
            duration: this.animationDurationLocal,
            easing: 'out',
          },
          (frame: any) => {
            if (frame.state.playState === 'running') {
              this.marker.setCoordinates(
                start.add({
                  x: offset.x * frame.state.delta,
                  y: offset.y * frame.state.delta,
                }),
              )
            }
            if (frame.state.playState === 'finished') {
              this.marker.setCoordinates(stop)
              if (hide) {
                this.marker.hide()
              }
            }
          },
        )
        player.play()
      }
    },
    click(event: any) {
      if (this.centerizeOnClick) {
        this.static.parentMap.panTo(this.location)
      }
      if (this.zoomOnClick) {
        this.static.parentMap.animateTo({
          center: this.location,
          zoom: this.static.parentMap.getZoom() + 2,
        })
      }
      this.$emit('click', event)
      if (this.hasPopupSlot) {
        this.markerGeometry.openInfoWindow()
        this.showPopup = true
        // don't know why hide event fires immediately
        this.popup.once('hide', () => {
          this.popup.once('hide', () => {
            this.showPopup = false
          })
        })
      }
    },
  },
})
