



















import Vue from 'vue'
import componentMap from './fieldsMap'
import InputField from './fields/InputField.vue'
import ErrorMessages from './common/ErrorMesseges.vue'
import { mapStateTyped } from '@/store'
import { intersection, isEqual, set } from 'lodash'
import { required } from 'vuelidate/lib/validators'

const STRINGS_REGEX = /[&?/#<>"'=;()]/
const DURATION_REGEX = /^((\d+y\s*)?(\s*)?(\d+M\s*)?(\s*)?(\d+w\s*)?(\s*)?(\d+d\s*)?(\s*)?(\d+h\s*)?(\s*)?(\d+m\s*)?(\s*)?(\d+s\s*)?)$/
export default Vue.extend({
  name: 'FieldsList',
  components: {
    ErrorMessages,
  },
  provide(): any {
    return {
      $v: this.$v,
      uid: this._uid,
    }
  },
  props: {
    widgetProps: Object as () => WidgetProps,
  },
  schema: [
    {
      mountPoint: 'localEntity',
      schema: {},
    },
  ],
  validations() {
    const validators = {} as Record<string, any>
    const fields = [...this.options.fields]
    fields.forEach((option: any) => {
      if (['string', 'text'].includes(option.type)) {
        set(validators, option.title, {
          schemaPattern: (val: string) => !val || !STRINGS_REGEX.test(val),
        })
      }
      if (option.type === 'duration') {
        set(validators, option.title, {
          durationPattern: (val: string) => {
            return typeof val === 'number' || !val || DURATION_REGEX.test(val)
          },
        })
      }
      if (['multiReference', 'reference'].includes(option.type)) {
        set(validators, option.title, {
          ...validators[option.title],
          isReferenceValid: (val: any) => {
            switch (true) {
              case typeof val === 'string':
                return this.existingReferenceIds.includes(val)
              case Array.isArray(val):
                return (
                  intersection(this.existingReferenceIds, val).length ===
                  val.length
                )
              default:
                return true
            }
          },
        })
      }
    })
    const requiredFields: string[] =
      this.options.schemaJson && this.options.schemaJson?.required
    if (requiredFields) {
      requiredFields.forEach((key) => {
        set(validators, key, { ...validators[key], required })
      })
    }
    return { localEntity: validators }
  },
  data() {
    return {
      InputField,
      componentMap,
      unsubscribe: () => {},
      localEntity: this.widgetProps.entity,
      options: this.widgetProps,
    }
  },
  computed: {
    ...mapStateTyped(['existingReferenceIds', 'api', 'currentLocale']),
    attributes(): any {
      const attributes = {} as Record<string, string>
      if (this.options.locale?.[this.currentLocale]?.attrs) {
        Object.entries(
          this.options.locale[this.currentLocale].attrs as Record<
            string,
            string
          >,
        ).forEach(([key, value]) => {
          attributes[key] = value
        })
      }
      return attributes
    },
  },
  watch: {
    widgetProps(val) {
      this.options = val
      if (!isEqual(this.options.entity, this.localEntity)) {
        this.localEntity = this.options.entity
      }
    },
  },
  async beforeMount() {
    this.initSchema()
  },
  destroyed() {
    this.unsubscribe()
  },
  methods: {
    commit(payload: IEntity) {
      this.localEntity = payload
      this.options.onEdit(payload)
    },
    initSchema() {
      const schema = {} as any
      const schemaCreate = this.options.schemaJson
        ? { ...this.options.schemaJson }
        : false
      if (schemaCreate) {
        schema.mountPoint = 'localEntity'
        delete schemaCreate.additionalProperties
        delete schemaCreate.type
        schema.schema = schemaCreate
      }
      this.$schema.push(schema)
    },
  },
})
