





































import Vue from 'vue'
import { debounce } from '@/utils'
import { mapGettersTyped, mapStateTyped } from '@/store'
import { get, set } from 'lodash'

export default Vue.extend({
  name: 'MultiReferenceField',
  props: {
    entity: Object,
    entityType: String,
    options: Object as () => {
      title: string
      type: 'multiReference'
      entityType: string
      labelField: string
    },
  },
  data() {
    return {
      loadingStatus: 'init' as FetchingStatus,
      fetchedEntitiesMap: {} as Record<string, IEntity>,
      limit: 10,
      count: 0,
      infiniteScroll: true,
      filterValue: '',
      wrapperElement: null as HTMLElement | null,
      isMaximumAlreadyReached: false,
    }
  },
  computed: {
    ...mapGettersTyped(['te']),
    ...mapStateTyped(['api']),
    loadingLocale(): string {
      return `${this.te('loading')}`
    },
    localValue: {
      set(val: string[]): void {
        const entity = { ...this.entity }
        set(entity, this.options.title, val)
        this.$emit('commit', entity)
      },
      get(): string[] {
        return get(this.entity, this.options.title) || []
      },
    },
    offset(): number {
      return this.refOptions.filter((id) =>
        this.getTitle(id)
          .toLowerCase()
          .includes(this.filterValue.toLowerCase()),
      ).length
    },
    refOptions(): string[] {
      return [
        ...this.localValue,
        ...Array.from(Object.keys(this.fetchedEntitiesMap)).filter(
          (id) => !this.localValue.includes(id),
        ),
        ...(this.loadingStatus === 'loading' ? [this.loadingLocale] : []),
      ]
    },
  },
  watch: {
    referenceIds: {
      handler(val) {
        val?.length && this.fetchValueEntities()
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchMoreEntities()
  },
  methods: {
    isDisabled(val: string) {
      return val === this.loadingLocale
    },
    onFilter({ value }: { value: string }) {
      this.filterValue = value
      this.infiniteScroll = true
      value && debounce(this.fetchMoreEntities, 300)
    },
    addScrollListener() {
      setTimeout(() => {
        this.wrapperElement = document.querySelector(
          '.p-multiselect-items-wrapper',
        )
        this.wrapperElement &&
          this.wrapperElement.addEventListener('scroll', this.onScroll)
      })
    },
    removeScrollListener() {
      this.wrapperElement &&
        this.wrapperElement.removeEventListener('scroll', this.onScroll)
    },
    onScroll() {
      if (this.wrapperElement) {
        const { offsetHeight, scrollHeight, scrollTop } = this.wrapperElement
        if (
          this.wrapperElement &&
          offsetHeight !== scrollHeight &&
          offsetHeight + scrollTop >= scrollHeight
        ) {
          !this.isMaximumAlreadyReached && this.fetchMoreEntities()
          this.isMaximumAlreadyReached = true
        } else {
          this.isMaximumAlreadyReached = false
        }
      }
    },
    getTitle(id: string): string {
      return this.loadingLocale !== id
        ? this.fetchedEntitiesMap?.[id]?.title || `${this.te('unknownRef')}`
        : this.loadingLocale
    },
    async fetchValueEntities() {
      const entities = this.localValue
        .map((id: string) => ({
          id,
          type: this.entityType,
        }))
        .filter(({ id }) => !this.fetchedEntitiesMap[id])
      if (entities.length === 0) {
        return
      }
      this.loadingStatus = 'loading'
      try {
        const { results } = (await this.api.batchQuery?.({
          limiter: {},
          filter: {
            entities: this.localValue.map((id: string) => ({
              id,
              type: this.entityType,
            })),
          },
        })) || { results: [] }
        const resultsMap = {} as Record<string, IEntity>
        results.forEach((result) => {
          resultsMap[result.id] = result
        })
        this.fetchedEntitiesMap = { ...this.fetchedEntitiesMap, ...resultsMap }
        this.loadingStatus = 'complete'
      } catch (error) {
        console.error(error)
        this.loadingStatus = 'error'
      }
    },
    async fetchMoreEntities() {
      if (!this.infiniteScroll) {
        return Promise.resolve()
      }
      try {
        this.loadingStatus = 'loading'
        const { count, results } = await this.api.getEntitiesList({
          limiter: {
            offset: this.offset,
            limit: this.limit,
            type: this.entityType,
            orderBy: 'title',
          },
          filter: {
            count: true,
            q: [
              ...(this.filterValue.length > 0
                ? [
                    {
                      key: 'title',
                      operator: '~=' as const,
                      value: this.filterValue,
                    },
                  ]
                : []),
            ],
          },
        })

        const resultsMap = {} as Record<string, IEntity>
        results.forEach((result) => {
          resultsMap[result.id] = result
        })
        this.fetchedEntitiesMap = { ...this.fetchedEntitiesMap, ...resultsMap }
        this.count = count || 0
        if (
          results.length === 0 ||
          this.count === Object.keys(this.fetchedEntitiesMap).length
        ) {
          this.infiniteScroll = false
        } else {
          this.infiniteScroll = true
        }
        this.loadingStatus = 'complete'
      } catch (error) {
        console.error(error)
        this.loadingStatus = 'error'
      }
    },
  },
})
